<template>
  <main id="error-code" class="next-container error-page error-page-mr">
    <section-base>
      <h1 class="color-primary-100 heading-col">
        Dieser Link<br />
        ist nicht mehr gültig
      </h1>

      <p class="color-accent-300 heading-col">
        Dieser Link ist nicht mehr gültig, weil diese Ablesung schon eingereicht
        wurde.
      </p>

      <button-regular
        class="button-accent"
        @click="$router.push({ name: 'CodeProvideReading' })"
      >
        Zurück zur Startseite
      </button-regular>
    </section-base>
  </main>
</template>

<script>
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { ButtonRegular, SectionBase }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
